/* Custom CSS for borders */
.matrix-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 5%;
    height: auto;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/astro-71.appspot.com/o/public%2Fswastik.png?alt=media&token=4c5ae127-5e08-43d1-8ecc-207b94030a94&_gl=1*vkybk6*_ga*MTk1NjAyMTQ2NC4xNjk2NzA0ODI5*_ga_CW55HF8NVT*MTY5NzIzMzkwMy4yMy4xLjE2OTcyMzQwOTYuNjAuMC4w');
    background-position: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #d9d9d9;
}

.matrix-table th,
.matrix-table th, .matrix-table td {
    border: 5px solid #0000004f;
    text-align: center;
    width: 33.33%;
    height: 0;
    padding-top: 33.33%;
    position: relative;
}

.matrix-table td span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.matrix-table th {
    background-color: #ffffff;
    opacity: 0.6;
}

.btn-xs {
    --bs-btn-padding-y: .25rem;
    --bs-btn-padding-x: .5rem;
    --bs-btn-font-size: .75rem;
}